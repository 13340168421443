import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/base/roomInfo/pageList", formData);
}

function create(parentId){
  if(parentId==null){
    parentId = "";
  }
  
  return request.get(constant.serverUrl + `/base/roomInfo/create?parentId=${parentId}`);
}

function edit(id){
  return request.get(constant.serverUrl + "/base/roomInfo/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/base/roomInfo/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/base/roomInfo/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/base/roomInfo/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/base/roomInfo/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function query(formData) {
  return request.post(constant.serverUrl + "/base/roomInfo/query", formData);
}

function queryTree(formData) {
  return request.post(constant.serverUrl + "/base/roomInfo/queryTree", formData);
}

export default {
  pageList,create,edit,add,update,remove,batchRemove,query,queryTree
}